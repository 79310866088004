<div class="container">
    <div class="heading">
        <!-- <img src="assets/icons/map-svgrepo-com.svg" alt=""> -->
        <div>
            What we offer for Counselor
        </div>
    </div>
    <div class="services-container">
        <div class="service-card">
            <img class="service-card-image" src="assets/icons/meet-happy.jpg" alt="">
            <div class="heading">
                Become a Local Buddy
            </div>
            <div class="service-card-details">
                If you are a local with deep knowledge and passion for your city, register now as a Counselor at Marv and benefit from support to organize your services and start earning.
            </div>
            <button class="primary-btn primary-btn--secondary secondary-btn" [routerLink]="['/services']" routerLinkActive="router-link-active" >
                Read more
            </button>
        </div>

        <div class="service-card">
            <img class="service-card-image" src="assets/icons/expert.jpg" alt="">
            <div class="heading">
                Become a Local Expert
            </div>
            <div class="service-card-details">
                If you are an experienced guide with passion for your job and looking to expand, register now as a Counselor at Marv to benefit from enhanced support and a career path to excellence.
            </div>
            <button class="primary-btn primary-btn--secondary  secondary-btn" [routerLink]="['/services']">
                Read more
            </button>
        </div>
    </div>
</div>