<div class="container">
    <div class="heading">
        <!-- <img src="assets/icons/map-svgrepo-com.svg" alt=""> -->
        <div>
            What we offer for Marveler
        </div>
    </div>
    <div class="services-container">
        <div class="service-card">
            <img class="service-card-image" src="assets/icons/video-call.svg" alt="">
            <div class="heading">
                Ask the Counselor
            </div>
            <div class="service-card-details">
                Chat with a Counselor or meet
                them on a video call to receive
                personalized recommendations,
                itineraries, culture impressions,
                and even some useful
                expressions in their language.
            </div>
            <button class="primary-btn primary-btn--primary" [routerLink]="['/services']" routerLinkActive="router-link-active" >
                Read more
            </button>
        </div>

        <div class="service-card">
            <img class="service-card-image" src="assets/icons/meet.svg" alt="">
            <div class="heading">
                Meet the Counselor
            </div>
            <div class="service-card-details">
                Meet with a Counselor in person
                to go on a guided tour carefully
                planned together, so it meets
                your preferences, needs, and
                interests. and unlock an
                authentic travel experience.
            </div>
            <button class="primary-btn primary-btn--primary" [routerLink]="['/services']">
                Read more
            </button>
        </div>
    </div>
</div>