<aside [ngStyle]="{'background-color': sidebarBackgroundColor}">
  <div class="listing">
    <ul>
      <li *ngFor="let item of items; trackBy: trackByTitle" #rla="routerLinkActive"
          [routerLinkActive]="['active']"
          [ngClass]="{'active': rla.isActive}"
          [ngStyle]="{'background-color': rla.isActive ? itemBackgroundColor : ''}">
        <img [src]="getIconPath(item.icon || '', rla.isActive)" [alt]="item.title + ' icon'" />
        <a [routerLink]="item.routerLink" routerLinkActive="active"
           [ngStyle]="{'color': rla.isActive ? 'white' : titleColor}">{{ item.title }}</a>
      </li>
    </ul>
    <ul>
      <li>
        <img [src]="logoutIcon" alt="logout icon" />
        <a (click)="logout()" [ngStyle]="{'color': titleColor}">Logout</a>
      </li>
    </ul>
  </div>
</aside>
