<div class="container">
    <div class="milo-container">
        <div class="image-container">
            <img src="assets/images/cat.jpg" alt="" class="image">
            <div class="image-title">
                Milo | CPO
            </div>
        </div>
        <div class="text">
            <b>Meet Milo, our CPO - Chief Pet Officer,</b> who takes part in all the
            board meetings, representing Marv's commitment towards
            animal fares and pet communities.<br><br>
            The Marv Tech culture includes efforts and dedication within the
            pet community, supporting our both internal and external
            stakeholders. We support our partners' furry companions, as well
            as collaborate with local shelters, clinics, and rescuing
            organizations in pursuing our vision of a world where animals
            are safe and loved in inclusive families.<br><br>
            Milo’s presence at MARV is a daily reminder of the joy pets bring
            to our lives. Milo is not just a mascot, he is an active member of
            the board. He might not be able to communicate all the
            decisions he would like to take, but we make sure we involve him
            in all the activities - he is ready to pawpprove any beneficial
            initiative for the pets. 
        </div>
    </div>
<img src="assets/images/footprint.jpg" alt="" class="footprint">
</div>