import { Component } from '@angular/core';

@Component({
  selector: 'app-milo',
  standalone: true,
  imports: [],
  templateUrl: './milo.component.html',
  styleUrl: './milo.component.scss'
})
export class MiloComponent {

}
