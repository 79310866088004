import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild,Renderer2 } from '@angular/core';

@Component({
  selector: 'app-gallery-carousel',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './gallery-carousel.component.html',
  styleUrl: './gallery-carousel.component.scss'
})
export class GalleryCarouselComponent {
  currdeg: number = 0;
  currentStep = 60;
  imagesArray = [{ url: "assets/images/1.webp", title: "X" },
  { url: "assets/images/2.webp", title: "X" },
  { url: "assets/images/3.webp", title: "X" },
  { url: "assets/images/4.webp", title: "X" },
  { url: "assets/images/5.webp", title: "X" },
  { url: "assets/images/6.webp", title: "X" }
  ]

  @ViewChild('carousel') carousel: ElementRef<HTMLElement>

  constructor(private renderer: Renderer2) {
  }
  ngAfterViewInit() {
    const items: NodeList = this.carousel.nativeElement.querySelectorAll('.item');
    this.currentStep = 360 / items.length;
    items.forEach((element: Node, index: number) => {
      const htmlElement = element as HTMLElement;
      const rotation = this.currentStep * index;
      htmlElement.style.transform = `rotateY(${rotation}deg) translateZ(430px)`;
    });
  }
  move(direction: string) {
    this.currdeg = this.currdeg + this.currentStep;
    this.carousel.nativeElement.style.transform = 'rotateY(' + this.currdeg + 'deg)';
  }
}
