import { Component, Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";

@Component({
  selector: "app-services",
  standalone: true,
  imports: [RouterModule, MatTooltipModule],
  templateUrl: "./services.component.html",
  styleUrl: "./services.component.scss",
})

export class ServicesComponent {
  
  tooltip = {
    marv: {
      desc: `- A Marveler is a traveler interested in authentic experiences crafted by local experts<br>
      - Register as a Marveler and we’ll match you with a Counselor (local buddy/expert) who shares your passions and interests<br>
      - Reach out to your Counselor for a simple consultation or meet them in person<br>
      - Enhance your traveling experiences by saving time searching for hidden gems and culture immersion<br>
      - With our SOS-M community you can get help fast directly from locals close to you for any unforeseen situation<br>
      `,
    },
    counselor: {
      desc: `
      - A Counselor is a local buddy/expert who crafts personalized experiences for their perfectly matched Marvelers (travelers)<br>
      - You can register as a Counselor whether you are an experienced guide or simply a local with deep knowledge and passion for your city<br>
      - We support you with courses and tips & tricks to enhance your skills and better promote your services, and a dedicated career path to grow your expertise and become a trusted local Pro Counselor<br>
      - With our SOS-C community you have other Counselors close to you, ready to help with any unforeseen situation<br>
      - In your dashboard, you can manage bookings, monitor your progress, and track your income<br>
      `,
    },
  };

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  scrollToCounselor() {
    const screenWidth = window.innerWidth;
    const scrollPosition = screenWidth >= 768 ? 2600 : 6000;
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  }
  
  scrollToMarv() {
    const screenWidth = window.innerWidth;
    const scrollPosition = screenWidth >= 768 ? 1400 : 2600;
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  }
  
}
