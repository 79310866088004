<div class="container">
    <div class="services-container">
        <div class="connector1"></div>
        <div class="connector2"></div>
        <div class="service-container marving">

            <div class="service">
                <span class="tooltip" [innerHTML]="tooltip.marv.desc"></span>
                <div class="box-shadow"></div>
                <div class="service-card">
                    <img class="service-card-image" src="assets/icons/traveler.svg" alt="">
                    <div class="service-card-title">
                        Marveler
                        <span class="serivce-subtitle">
                            Find a local expert<br>
                            wherever you<br>
                            travel
                        </span>
                    </div>
                    <div class="action-btn">
                        <button class="primary-btn primary-btn--primary" (click)="scrollToMarv()">Start
                            Marving</button>
                    </div>

                </div>
            </div>
        </div>
        <img class="moving-image" src="assets/images/final favicon.png" alt="Moving Image">
        <div class="service-container">
            <div class="service counseling">
                <span class="tooltip-left" [innerHTML]="tooltip.counselor.desc"></span>
                <div class="box-shadow"></div>
                <div class="service-card">
                    <img class="service-card-image" src="assets/icons/hike.svg" alt="">
                    <div class="service-card-title">
                        Counselor
                        <span class="serivce-subtitle">
                            showcase your<br>
                            expertise on your<br>
                            city
                        </span>
                    </div>
                </div>
                <div class="action-btn">
                    <button class="primary-btn primary-btn--secondary" (click)="scrollToCounselor()">Start
                        Counseling</button>
                </div>
            </div>
        </div>
    </div>
</div>