<div class="container">
    <div class="containerSlider">
        <div class="carousel" #carousel>
            <!-- <img src="assets/images/final favicon.png" alt="" width="50px" height="50px" class="mid-center-rotate" /> -->
            <div class="item" *ngFor="let item of imagesArray; let i = index" 
            [ngStyle]="{'background-image': 'url(' + item.url + ')'}" >
            </div>
        </div>
    </div>
</div>
<!-- <div class="next" (click)="move('n')">Next</div>
<div class="prev" (click)="move('p')">Prev</div> -->