<div class="container d-none">
    <div class="heading">
        <img src="assets/icons/message-square-list-svgrepo-com.svg" alt="">
        <div>
            How it works
        </div>
    </div>
    <div class="benefits-container">
        <!-- <div class="tabs">
            <div class="tab" [class.tab-selected]="tabOneSelected" (click)="onTabSelected('marveler')">Marveler</div>
            <div class="tab" [class.tab-selected]="tabTwoSelected" (click)="onTabSelected('counselor')">counselor</div>
        </div> -->
        <div class="benefits-grid">
            <div class="marveler">
                <div class="icon">
                    <img src="assets/icons/airplane.svg" alt="">
                </div>
                <div class="marveler-container">
                    <div class="marveler-info">
                        <h3>
                            What can you do as a Marveler?
                        </h3>

                        <ul>
                            <li>
                                Create your profile with your interests and passions
                            </li>
                            <li>
                                Search for travel services or guides that match your preferences
                            </li>
                            <li>
                                Book and enjoy your travel experience, and share your feedback and stories with the MARV
                                community
                            </li>
                        </ul>
                    </div>
                    <div class="marveler-benefits">
                        <h3>
                            What are your benefits?
                        </h3>

                        <ul>
                            <li>
                                Customized Experience
                            </li>
                            <li>
                                Affordability
                            </li>
                            <li>
                                On-demand service
                            </li>
                            <li>
                                Networking
                            </li>
                            <li>
                                Rating system
                            </li>
                            <li>
                                Safety
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="action-btn">
                    <button class="primary-btn primary-btn--white">
                        Join Marvling
                    </button>
                </div>

            </div>
            <div class="marveler">
                <div class="icon">
                    <img src="assets/icons/map-small.svg" alt="">
                </div>
                <div class="marveler-container">
                    <div class="marveler-info">
                        <h3>
                            What can you do as a Counselor?
                        </h3>

                        <ul>
                            <li>
                                Create your profile, showcasing your abilities, experience, and authenticity
                            </li>
                            <li>
                                Create your travel service and offer it to Marvelers
                            </li>
                            <li>
                                Share your feedback and stories with the MARV community
                            </li>
                        </ul>
                    </div>
                    <div class="marveler-benefits">
                        <h3>
                            What are your benefits?
                        </h3>

                        <ul>
                            <li>
                                Income generation
                            </li>
                            <li>
                                Flexible & predictable working hour
                            </li>
                            <li>
                                No asset consumption
                            </li>
                            <li>
                                Rewards Program
                            </li>
                            <li>
                                Rating system
                            </li>
                            <li>
                                Networking
                            </li>
                            <li>
                                Carrier Progression
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="action-btn">
                    <button class="primary-btn primary-btn--white">
                        Join Counseling
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="heading">
        <img src="assets/icons/map-svgrepo-com.svg" alt="">
        <div>
            What we offer?
        </div>
    </div>
    <div class="services-container">
        <div class="service-card">
            <img class="service-card-image" src="assets/icons/video-call.svg" alt="">
            <div class="heading">
                Ask the Counselor
            </div>
            <div class="service-card-details">
                Chat with a Counselor or meet
                them on a video call to receive
                personalized recommendations,
                itineraries, culture impressions,
                and even some useful
                expressions in their language.
            </div>
            <button class="primary-btn primary-btn--primary" [routerLink]="['/coming-soon']" routerLinkActive="router-link-active" >
                Read more
            </button>
        </div>

        <div class="service-card">
            <img class="service-card-image" src="assets/icons/meet.svg" alt="">
            <div class="heading">
                Meet the Counselor
            </div>
            <div class="service-card-details">
                Meet with a Counselor in person
                to go on a guided tour carefully
                planned together, so it meets
                your preferences, needs, and
                interests. and unlock an
                authentic travel experience.
            </div>
            <button class="primary-btn primary-btn--primary" [routerLink]="['/coming-soon']">
                Read more
            </button>
        </div>
    </div>
</div>