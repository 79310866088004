import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterLink, RouterModule } from "@angular/router";
import { SidebarService } from "./services/sidebar.service";
import { ISidebarItem } from "./interfaces/ISidebarItem";
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule],
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  items: ISidebarItem[] = [];
  logoutIcon: string = "assets/icons/logout-pink.svg";
  sidebarBackgroundColor: string = "var(--primary-color)";
  titleColor: string = "var(--primary-color)";
  itemBackgroundColor: string = "";
  iconColor: string = "white";

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const userType = localStorage.getItem("userType");
    if (userType === "marveler") {
      this.itemBackgroundColor = "var(--primary-color)";
      this.iconColor = "white";
    } else if (userType === "counselor") {
      this.itemBackgroundColor = "var(--secondary-color)";
      this.iconColor = "white";
    }

    this.sidebarService.navItems.subscribe((items: ISidebarItem[]) => {
      this.items = items;
    });

    this.sidebarService.sidebarBgColor.subscribe((color) => {
      this.sidebarBackgroundColor = color;
    });

    this.sidebarService.titleTextColor.subscribe((color) => {
      this.titleColor = color;
    });

    this.sidebarService.logoutIconPath.subscribe((icon) => {
      this.logoutIcon = icon;
    });
  }

  getIconPath(iconPath: string, isActive: boolean): string {
    if (!iconPath) {
      return "";
    }
    if (isActive) {
      return iconPath.replace(/-(pink|green)\.svg$/, `-${this.iconColor}.svg`);
    }
    return iconPath;
  }

  trackByTitle(index: number, item: ISidebarItem): string {
    return item.title;
  }
  logout(): void {
    this.authService.logout();
  }
}
