<div class="container">
    <app-nav></app-nav>
    <!-- <app-sidebar></app-sidebar> -->
    <!-- <app-dashboard></app-dashboard> -->
    <app-gallery-carousel></app-gallery-carousel>
    <!-- <app-banner></app-banner> -->
    <app-services></app-services>
    <app-join-now></app-join-now>
    <app-how-marv-works></app-how-marv-works>
    <app-what-marv-offers></app-what-marv-offers>
    <app-benefits></app-benefits>
    <app-how-counselor-works></app-how-counselor-works>
    <app-what-counselor-offers></app-what-counselor-offers>
    <app-map></app-map>
    <app-milo></app-milo>
    <app-footer></app-footer>
    <!-- <app-signup-ai *ngIf="openChat" (chatData)="receiveDataFromBanner($event)"></app-signup-ai> -->
</div>