import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-benefits',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './benefits.component.html',
  styleUrl: './benefits.component.scss'
})
export class BenefitsComponent {
  tabOneSelected: boolean = true;
  tabTwoSelected: boolean = false; 

  onTabSelected(type: string) {
    if (type === 'marveler') {
      this.tabOneSelected = true;
      this.tabTwoSelected = false;
    } else {
      this.tabOneSelected = false;
      this.tabTwoSelected = true;
    }
  }
}
