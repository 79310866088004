import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-what-marv-offers',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './what-marv-offers.component.html',
  styleUrl: './what-marv-offers.component.scss'
})
export class WhatMarvOffersComponent {

}
