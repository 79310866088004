<div class="container">
    <div class="heading">
        <!-- <img src="assets/icons/map-svgrepo-com.svg" alt=""> -->
        <div>
            How Marving Works
        </div>
    </div>
    <div class="cards-container">
        <div class="card">
            <img src="assets/icons/settings.svg" alt="" class="card-image">
            <div class="card-text">
                Register and customize your profile
            </div>
        </div>
        <img src="assets/icons/next-right.svg" alt="" class="next">
        <div class="card">
            <img src="assets/icons/puzzle.svg" alt="" class="card-image">
            <div class="card-text">
                Search for a destination and get instantly matched
            </div>
        </div>
        <img src="assets/icons/next-right.svg" alt="" class="next">
        <div class="card">
            <img src="assets/icons/calender.svg" alt="" class="card-image">
            <div class="card-text">
                Book the service(s) you wanted based on their availability
            </div>
        </div>
        <img src="assets/icons/next-right.svg" alt="" class="next">
        <div class="card">
            <img src="assets/icons/meeting-discussion.svg" alt="" class="card-image">
            <div class="card-text">
                Have an online consultation and/or meet them in person
            </div>
        </div>
        <img src="assets/icons/next-right.svg" alt="" class="next">
        <div class="card">
            <img src="assets/icons/network.svg" alt="" class="card-image">
            <div class="card-text">
                Give a feedback and add them to your global network
            </div>
        </div>
    </div>
    <div class="join-now marv">
        <div>
            Pre-Register and Get <span style="font-size: 36px;text-shadow: 0 0 5px white;"><br>30%
                off<br></span> on your first trip.
        </div>
        <div class="button" [routerLink]="['/form-marv']">
            JOIN NOW
        </div>
        <br>
        <div>
            August Launch! Mark Your Calendars!
        </div>
    </div>
</div>