import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-how-marv-works',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './how-marv-works.component.html',
  styleUrl: './how-marv-works.component.scss'
})
export class HowMarvWorksComponent {

}
