<!-- <div class="heading ptb-100">
    <img src="assets/icons/world-svgrepo-com.svg" alt="">
    <div>
        Explore the world
    </div>
</div>
<div class="map">
    <google-map #map height="600px" width="100%" [options]="options" (mapInitialized)="mapReady(map)"
        (mapClick)="onMapClick($event)">
        @for (custMarker of customMarkers; track custMarker; let index = $index) {
        <map-advanced-marker #marker [position]="custMarker.customPosition"
        [content]="custMarker.content" [options]="custMarker.markerOptions">
        </map-advanced-marker>
        }
    </google-map>
    <button (click)="addMarker(42.8145121,-1.6440935)">XXXXXXXXXXX</button>
</div> -->

<div class="map">
    <div class="search-container">
        <div class="search-input">
            <img class="search-icon" src="assets/icons/search.svg" alt="">
            <input type="text" placeholder="Where are you traveling?" id="pac-input">
        </div>
    </div>

    <google-map #map height="600px" width="100%" [options]="options" (mapInitialized)="mapReady(map)"
        (mapClick)="onMapClick($event)">
        @for (custMarker of customMarkers; track custMarker; let index = $index) {
        <map-advanced-marker #markerElem="mapAdvancedMarker" [position]="custMarker.customPosition" [content]="custMarker.content"
        [options]="custMarker.markerOptions" (mapClick)="onMarkerClick(markerElem)">
        </map-advanced-marker>
        }
    </google-map>
</div>