import { Component, QueryList, ViewChild, ViewChildren, ChangeDetectorRef } from "@angular/core";
import { GoogleMapsModule, GoogleMap, MapAdvancedMarker, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from "@angular/router";

@Component({
  selector: "app-map",
  standalone: true,
  imports: [GoogleMapsModule],
  templateUrl: "./map.component.html",
  styleUrl: "./map.component.scss",
})
export class MapComponent {
  options: google.maps.MapOptions = {
    center: { lat: 51.1657, lng: 10.4515 },
    zoom: 5,
    mapId: "DEMO_MAP_ID",
    mapTypeId: 'roadmap',
    draggable: true,
    disableDefaultUI: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9d0759"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#57cdff"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]
  };
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  @ViewChild(GoogleMap, { static: true }) map!: GoogleMap;
  @ViewChildren(MapAdvancedMarker) markerElem: QueryList<MapAdvancedMarker>;
  features: google.maps.Data.Feature[] = [];
  zoomLevel = 0;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    title: "Hello World!"
  };
  markers = [
    // paris
    { lat: 48.8566, lng: 2.3522 },
    // rome
    { lat: 41.9028, lng: 12.4964 },
    // vienna
    { lat: 48.2082, lng: 16.3738 },
    // bucharest
    { lat: 44.4268, lng: 26.1025 },
    // london
    { lat: 51.5074, lng: 0.1278 },
    // madrid
    { lat: 40.4168, lng: 3.7038 },
    // moscow
    { lat: 55.7558, lng: 37.6176 },
    // athens
    { lat: 37.9838, lng: 23.7275 },
    // budapest
    { lat: 47.4979, lng: 19.0402 },
    // warsaw
    { lat: 52.2297, lng: 21.0122 },
    // amsterdam
    { lat: 52.3667, lng: 4.8945 },
    // brussels
    { lat: 50.8503, lng: 4.3517 },
    // prague
    { lat: 50.0755, lng: 14.4378 },
    // stockholm
    { lat: 59.3293, lng: 18.0686 },
    // berlin
    { lat: 52.5200, lng: 13.4050 },
    // copenhagen
    { lat: 55.6761, lng: 12.5683 },
    // helsinki
    { lat: 60.1695, lng: 24.9354 },
    // oslo
    { lat: 59.9139, lng: 10.7522 },
    // lisbon
    { lat: 38.7223, lng: 9.1393 },
    // sofia
    { lat: 42.6977, lng: 23.3219 },
    // pristina
    { lat: 42.6629, lng: 21.1655 },
    // lyon
    { lat: 45.7640, lng: 4.8357 },
    // marseille
    { lat: 43.2965, lng: 5.3698 },
    // milan
    { lat: 45.4642, lng: 9.1900 },
    // madrid
    { lat: 40.4168, lng: 3.7038 },
    // lisbon
    { lat: 38.7223, lng: 9.1393 },
  ];
  customMarkers: any[] = []
  constructor(private cdf: ChangeDetectorRef, private router: Router) { }
  ngOnInit() { }
  ngAfterViewInit() { }

  onMarkerClick(marker: MapAdvancedMarker) {
    this.router.navigate(['preregister']);
  }
  onMapClick(event: google.maps.MapMouseEvent) {
    if (this.zoomLevel === 1) {
      this.features = [];
    }
  }
  async mapReady(map: GoogleMap) {
    // this.initMap();
    this.markers.forEach((marker) => {
      const randomNumber = Math.floor(Math.random() * (30 - 5 + 1)) + 5;
      this.addMarker(marker.lat, marker.lng, randomNumber);
    });

    await google.maps.importLibrary("places");
    var input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    input.addEventListener("keydown", function (event) {
      if (event.code === 'Enter') {
        event.preventDefault();
      }
    });
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (!places) {
        return;
      }
      if (places?.length == 0) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        this.customMarkers = []
        const randomNumber = Math.floor(Math.random() * (30 - 5 + 1)) + 5;
        this.addMarker(place.geometry.location.lat(), place.geometry.location.lng(), randomNumber);
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        this.map.fitBounds(bounds);
      });
    });
  }
  // initMap(): void {

  //   this.map.data.loadGeoJson('../../../assets/data/country_borders.json', null, (a: google.maps.Data.Feature[]) => {
  //     a.forEach(element => {
  //       element.setProperty("color", 'white');
  //     })
  //     this.clearMap(0);
  //     console.log('dataloaded: 1')
  //   })

  //   // this.map.data.loadGeoJson('https://github.com/johan/world.geo.json/blob/master/countries/ROU.geo.json');
  //   this.map.data.setStyle((feature) => {
  //     return {
  //       fillColor: feature.getProperty("color") as string || "white",
  //       strokeWeight: 0.5,
  //       strokeColor: feature.getProperty("color") as string || "#9D0759",
  //       zIndex: feature.getProperty("zIndex") as number || 0
  //     };
  //   });
  //   this.map.data.addListener("mouseover", (event: google.maps.Data.MouseEvent) => {
  //     this.map.data.revertStyle();
  //     this.map.data.overrideStyle(event.feature, { strokeWeight: 1, fillColor: '#12676A' });
  //   });
  //   this.map.data.addListener("mouseout", (event: google.maps.Data.MouseEvent) => {
  //     this.map.data.revertStyle();
  //   });
  //   this.map.data.addListener("click", (event: google.maps.Data.MouseEvent) => {
  //     const lat = event.latLng?.lat() || 0;
  //     const lng = event.latLng?.lng() || 0;
  //     this.addMarker(lat, lng);
  //     // this.customMarkers[0].customPosition = { lat: lat, lng: lng };


  //     const polygon = event.feature;
  //     this.map.data.loadGeoJson('../../../assets/data/ADM1/geoBoundaries-' + polygon.getProperty("ISO_A3") + '-ADM1_simplified.geojson', null, (a: google.maps.Data.Feature[]) => {
  //       this.clearMap(1);
  //       a.forEach(element => {
  //         element.setProperty("zIndex", 1);
  //         element.setProperty("color", '#9D0759');
  //       })
  //       this.features = a;
  //       console.log('dataloaded: 2')
  //     })


  //     const bounds = new google.maps.LatLngBounds();
  //     polygon.getGeometry()?.forEachLatLng((latLng) => {
  //       bounds.extend(latLng);
  //     });
  //     this.map.fitBounds(bounds);

  //   });
  // }
  checkPointInPolygon(lat: number, lng: number, polygon: google.maps.Polygon) {
    if (polygon) {
      // const isInside = google.maps.geometry.poly.containsLocation(
      //   new LatLng(lat, lng), polygon.getPaths().getAt(0)
      // );
      // if (isInside) {
      //   console.log('Point is inside the polygon!');
      //   this.addMarker(lat, lng); // Add marker if point is inside
      // } else {
      //   console.log('Point is outside the polygon.');
      // }
    }
  }
  // clearMap(zoomLevel: number) {
  //   this.features.forEach(element => {
  //     this.map.data.remove(element);
  //   });
  //   this.features = [];
  //   this.zoomLevel = zoomLevel;
  // }

  addMarker(lat: number, lng: number, ranNumber: number = 0) {
    const priceTag = document.createElement('div');
    priceTag.className = 'price-tag';
    priceTag.textContent = ranNumber + ' Counselors';
    this.customMarkers.push({
      customPosition: { lat: lat, lng: lng },
      markerOptions: this.markerOptions,
      content: priceTag,
    });
    this.cdf.detectChanges();
  }

}

