<div class="join-now-container">
    <div class="join-now marv">
        <div>
            Pre-Register and Get <span style="font-size: 36px;text-shadow: 0 0 5px white;"><br>30% off<br></span> on your first trip.
        </div>
        <div class="button" [routerLink]="['/preregister']">
            Join Now
        </div>
        <br>
        <div>
            August Launch!  Mark Your Calendars!
        </div>
    </div>
    <div class="join-now">
        <div>
            Pre-Register and Get <span style="font-size: 36px;text-shadow: 0 0 5px white;"><br>30% off<br></span> on your first trip.
        </div>
        <div class="button" [routerLink]="['/preregister']">
            Join Now
        </div>
        <br>
        <div>
            August Launch!  Mark Your Calendars!
        </div>
    </div>
</div>