<div class="container">
    <div class="heading">
        <!-- <img src="assets/icons/map-svgrepo-com.svg" alt=""> -->
        <div>
            How Counseling Works
        </div>
    </div>
    <div class="cards-container">
        <div class="card">
            <img src="assets/icons/settings.svg" alt="" class="card-image">
            <div class="card-text">
                Register and customize your profile
            </div>
        </div>
        <img src="assets/icons/next-right-secondary.svg" alt="" class="next">
        <div class="card">
            <img src="assets/icons/dollar.svg" alt="" class="card-image">
            <div class="card-text">
                Set your price according to your experience
            </div>
        </div>
        <img src="assets/icons/next-right-secondary.svg" alt="" class="next">
        <div class="card">
            <img src="assets/icons/approved-1.svg" alt="" class="card-image">
            <div class="card-text">
                Approve/Cancel requests in the allocated time
            </div>
        </div>
        <img src="assets/icons/next-right-secondary.svg" alt="" class="next">
        <div class="card">
            <img src="assets/icons/video-call-white.svg" alt="" class="card-image">
            <div class="card-text">
                Consult Marvelers and/or meet them
            </div>
        </div>
        <img src="assets/icons/next-right-secondary.svg" alt="" class="next">
        <div class="card">
            <img src="assets/icons/network.svg" alt="" class="card-image">
            <div class="card-text">
                Give a feedback and add them to your global network
            </div>
        </div>
    </div>
    <div class="join-now">
        <div>
            Pre-Register now and get featured on our Social Media profiles.
        </div>
        <div class="button" [routerLink]="['/form-co']">
            JOIN NOW
        </div>
        <br>
        <div>
            August Launch! Mark Your Calendars!
        </div>
    </div>
</div>