import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule, RouterOutlet } from '@angular/router';
import { BannerComponent } from '../../components/banner/banner.component';
import { ServicesComponent } from '../../components/services/services.component';
import { AboutMarvComponent } from '../../components/about-marv/about-marv.component';
import { FeaturesComponent } from '../../components/features/features.component';
import { ComingSoonComponent } from '../../components/coming-soon/coming-soon.component';
import { SignUpAIComponent } from '../signup-ai/signup-ai.component';
import { MapComponent } from '../../components/map/map.component';
import { BenefitsComponent } from '../../components/benefits/benefits.component';
import { FooterComponent } from '../../components/footer/footer.component';

import { Pseudo3dCarouselComponent } from '../../components/pseudo-3d-carousel/pseudo-3d-carousel.component';
import { NavComponent } from '../../components/nav/nav.component';
import { SidebarComponent } from '../../layouts/sidebar/sidebar.component';
import { GalleryCarouselComponent } from '../../components/gallery-carousel/gallery-carousel.component';
import { HowMarvWorksComponent } from '../../components/how-marv-works/how-marv-works.component';
import { HowCounselorWorksComponent } from '../../components/how-counselor-works/how-counselor-works.component';
import { JoinNowComponent } from '../../components/join-now/join-now.component';
import { WhatCounselorOffersComponent } from '../../components/what-counselor-offers/what-counselor-offers.component';
import { WhatMarvOffersComponent } from '../../components/what-marv-offers/what-marv-offers.component';
import { MiloComponent } from '../../components/milo/milo.component';
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    BannerComponent,
    ServicesComponent,
    AboutMarvComponent,
    FeaturesComponent,
    ComingSoonComponent,
    SignUpAIComponent,
    MapComponent,
    Pseudo3dCarouselComponent,
    BenefitsComponent,
    FooterComponent,
    MatIconModule,
    MatMenuModule,
    NavComponent,
    GalleryCarouselComponent,
    RouterModule,
    HowMarvWorksComponent,
    HowCounselorWorksComponent,
    JoinNowComponent,
    WhatCounselorOffersComponent,
    WhatMarvOffersComponent,
    MiloComponent,
    SidebarComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  openChat: boolean = false;
  showMenu: boolean = false;
}
